import React from 'react';
import GradientOverlay from './../GradientOverlay';
import NoscriptTogglePure from './../../NoscriptToggle';
import {
    Wrapper
} from './styledComponents';

const Pure = () => {
    return (
        <>
            <NoscriptTogglePure labelOff="SHOW ME"/>
            <Wrapper>
                <GradientOverlay />
                <video loop muted autoPlay controls={true}>
                    <source src="/static/explorer-voyage.webm" type="video/webm" />
                    <source src="/static/explorer-voyage.mp4" type="video/mp4" />
                    Sorry. This video is not supported by your browser.
                </video>  
            </Wrapper>
        </>
    );
}

export default Pure;