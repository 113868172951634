import React from 'react';
import Octicon, {
    Info, 
    Database, 
    ListOrdered, 
    Pin, 
    Gear, 
    Location,
    Star,
    CircleSlash
} from '@githubprimer/octicons-react'
import SectionHeader from './../../../Header/Section';
import {
    FeaturesContainer,
    FeatureSnippet
} from './styledComponents';

const Pure = () => {
    return (
        <FeaturesContainer>
            <SectionHeader flex="1 0 100%">Sea Routing Features</SectionHeader>
            <FeatureSnippet 
                aspect="“Any-to-Any” Routing"
                description={`
                    Use any sea location for calculation. 
                    Port to Port, Point to Port or Point to Point. 
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={Location}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="17000+ Locations"
                description={`
                    Database containing ports, blocks and 
                    offshore installations.
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={Database}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="Channel Selection"
                description={`
                    Cover different scenarios by closing or opening 
                    channels (Suez, Panama, Kiel) and passages 
                    (Northeast, Northwest).
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={Gear}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="Custom Nogo Areas"
                description={`
                    Prohibit passage through shallow channels, avoid 
                    bad weather or customize routes based on vessel type.
                    Simply by drawing custom polygons that our routing 
                    algorithm should avoid.
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={Star}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="Multi-leg Routing"
                description={`
                    Customize voyage calculations 
                    by setting routing waypoints mid-way or 
                    plan multiple legs.
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={ListOrdered}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="(S)ECA Distance"
                description={`
                    Total of 9 ECA areas around the globe integrated.
                    Automatically avoid/minimize distance travelled within.
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={Info}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="HRA & JWC Listed Areas"
                description={`
                    Both the High Risk Area as well as Joint War Committee 
                    Listed Areas included.
                    Automatically avoid/minimize distance travelled within. 
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={CircleSlash}/>
                    </>
                }
                actionLinks={[]}
            />
            <FeatureSnippet 
                aspect="Store Points of Interest"
                description={`
                    Save time by storing frequently used locations 
                    or waypoints as points of interest.
                `}
                icon={
                    <>
                        <Octicon size="medium" icon={Pin}/>
                    </>
                }
                actionLinks={[]}
            />
        </FeaturesContainer>
    )
}

export default Pure;