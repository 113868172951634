import styled from 'styled-components';
import { Colors } from './../../aquaplot/colors';

export const FlexUl = styled.ul`
    display: flex;
    justify-content: center;
    margin-left: 0px;
    background-color: ${Colors.aqp_deep_sea};
    padding-top: 10px;
    padding-bottom: 10px;
    background: radial-gradient(rgba(7,51,82,0.4) 5%,rgba(7,51,82,0.7) 75%,#073352 90%);

    @media (min-width: 700px){
        li:not(:last-child){
            margin-right: 32px;
        }
        li:first-child{
            list-style: none;
        }
    }

    @media (max-width: 699px){
        flex-direction: column;
    }
`