import styled from 'styled-components';
import { Colors } from './../../aquaplot/colors';
import ActionButtonLink from './../ActionButton';

export const WrapperDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1%:
    padding-right: 1%;
`

export const Title = styled.span`
    font-size: 1.5em;
    color: ${Colors.aqp_deep_sea};
    text-align: center;
`

export const SubTitle = styled.span`
    font-size: 1em;
    color: ${Colors.aqp_deep_sea};
    text-align: center;
`

export const StyledActionButton = styled(ActionButtonLink)`
    &{
        margin-top: 20px;
        margin-bottom: 50px;
        padding: 15px;
    }
`