import styled from 'styled-components';
import PlatformAspectCard from './../../../Cards/PlatformAspect';
import { Colors } from './../../../../aquaplot/colors';

export const FeaturesContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    background-color: ${Colors.aqp_coastal_water};
    color: ${Colors.aqp_bg_light_gray};
    width: 100%;
`

export const FeatureSnippet = styled(PlatformAspectCard)`
    & {
        box-shadow: none;
        margin: 2%;
        div{
            width: unset;
        }
    }
`