import React from 'react';
import {
    WrapperDiv,
    Title,
    SubTitle,
    StyledActionButton
} from './styledComponents';

const Pure = ({
    title,
    subtitle,
    linkTo,
    label
}) => {
    return (
        <WrapperDiv>
            <Title>{ title }</Title>
            <SubTitle>{ subtitle }</SubTitle>
            <StyledActionButton href={linkTo}>
                { label }
            </StyledActionButton>
        </WrapperDiv>
    )
}

export default Pure;