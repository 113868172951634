import React from 'react';
import {
    FlexUl
} from './styledComponents';

const Pure = () => {
    return (
        <FlexUl>
            <li>Distance Table</li>
            <li>Vessel Tracking (AIS)</li>
            <li>Weather</li>
            <li>And More</li>
        </FlexUl>
    )
}

export default Pure;