import styled from 'styled-components';
import ActionButton from './../ActionButton';

export const HiddenInput = styled.input`
    display: none;
`

export const StyledLabel = styled(ActionButton)`
    & {
        position: absolute;
        z-index: 10;
        border-radius: 20px;

        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        transition: all 500ms ease-in-out;
        pointer-events: all;

        :hover{
            background-color: white;
        }

        input:not(:checked) ~ div>& > div::before{
            content: attr(data-toggle-off);
        }
          
        input:checked ~ div>& > div::before{
            content: attr(data-toggle-on);
        }

        input:checked ~ div>&{
            right: 1%;
            text-align: left;
        }
    }
`

export const LabelContainer = styled.div`
    position: relative;
    min-height: 50px;
`