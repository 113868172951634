import React from 'react';
import {
    HiddenInput,
    StyledLabel,
    LabelContainer
} from './styledComponents';

const NoscriptTogglePure = ({
    labelOff
}) => {
    return (
        <>
            <HiddenInput type='checkbox' id="hidden-toggle-checkbox" />
            <LabelContainer>
                <StyledLabel actionType="label" htmlFor="hidden-toggle-checkbox">
                    <div data-toggle-on={"<"} data-toggle-off={labelOff} />
                </StyledLabel>
            </LabelContainer>
        </>
    )
}

export default NoscriptTogglePure;