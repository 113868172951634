import React from 'react'
import { graphql } from "gatsby"
import Img from "gatsby-image"
import RoutingFeaturesOverview from './../components/Explorer/Routing/FeaturesOverview';

import Layout from '../components/Layout'
import SectionHeader from './../components/Header/Section';
import CallToAction from './../components/CallToAction';

import Video from './../components/Landingpage/Video';
import FlexList from './../components/FlexList';
import { Header } from './../components/Landingpage/Header';

const IndexPage = ({ data }) => (
  <Layout>
    <Header>
        <div className="landingpage-header-content">
            <div className="landingpage-header-content-text">
                <h1>One-Stop Maritime Applications</h1>
                <p>Next-generation web-app powered by industry-leading algorithms.</p>
                <FlexList />
                <Video />
            </div>
        </div>
    </Header>

    <RoutingFeaturesOverview />

    <div style={{
        paddingTop: '2em',
        paddingBottom: '1em',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <SectionHeader maxWidth="300px">
            Explorer works across all your devices.
        </SectionHeader>
        <div style={{width: '80vw', maxWidth: 480}}>
            <Img 
                fluid={data.comboImage.childImageSharp.fluid}
                fadeIn={true}
            />
        </div>
    </div>

    <CallToAction 
        title="Over 12,000+ professionals have signed up worldwide."
        subtitle={
            <span>
                Your account will be free - forever - and 
                registration only takes a few seconds. <br></br>
                No credit card required.
            </span>
        }
        linkTo="https://app.aquaplot.com"
        label="Go to sign up"
    />

    <aside id="placeholder-notice" className="notice">
        We are currently redesigning large sections of this website.
        Check back later for more detailed information and updates.
    </aside>

  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    comboImage: file(relativePath: { eq: "combo-underlined.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
