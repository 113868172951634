import styled from 'styled-components';
import { Colors } from './../../../aquaplot/colors';

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: url('/static/screenshot-explorer-windy.jpg');
    background-size: cover;
    background-position: center;

    input:checked ~ & { 
        background: ${Colors.aqp_deep_sea}; 
        z-index: 9; 
    }
    input:checked ~ &>:not(video) { 
        display: none; 
    }

    video {
        height: 100%;
        width: 100%;
        z-index: 10;
        pointer-events: all;
    }

    input:not(:checked) ~ & {
        z-index: -1;
    }
    
    input:not(:checked) ~ & > video { display: none; }
`